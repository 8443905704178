import locale77427e78 from '../../locales/en.js'
import locale77304fa0 from '../../locales/de.js'
import locale77526775 from '../../locales/fr.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: false,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en.js","flag":"flag-en.svg"},{"code":"de","iso":"de-DE","file":"de.js","flag":"flag-de.svg"},{"code":"fr","iso":"fr-FR","file":"fr.js","flag":"flag-fr.svg"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: "~/locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en.js","flag":"flag-en.svg"},{"code":"de","iso":"de-DE","file":"de.js","flag":"flag-de.svg"},{"code":"fr","iso":"fr-FR","file":"fr.js","flag":"flag-fr.svg"}],
  localeCodes: ["en","de","fr"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "o",
  15: "c",
  16: "a",
  17: "l",
  18: "e",
  19: "s",
  20: "/",
  21: "e",
  22: "n",
  23: ".",
  24: "j",
  25: "s",
  26: "\"",
  27: ",",
  28: "\"",
  29: "d",
  30: "e",
  31: ".",
  32: "j",
  33: "s",
  34: "\"",
  35: ":",
  36: "\"",
  37: ".",
  38: ".",
  39: "/",
  40: "l",
  41: "o",
  42: "c",
  43: "a",
  44: "l",
  45: "e",
  46: "s",
  47: "/",
  48: "d",
  49: "e",
  50: ".",
  51: "j",
  52: "s",
  53: "\"",
  54: ",",
  55: "\"",
  56: "f",
  57: "r",
  58: ".",
  59: "j",
  60: "s",
  61: "\"",
  62: ":",
  63: "\"",
  64: ".",
  65: ".",
  66: "/",
  67: "l",
  68: "o",
  69: "c",
  70: "a",
  71: "l",
  72: "e",
  73: "s",
  74: "/",
  75: "f",
  76: "r",
  77: ".",
  78: "j",
  79: "s",
  80: "\"",
  81: "}",
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'de.js': () => Promise.resolve(locale77304fa0),
  'fr.js': () => Promise.resolve(locale77526775),
}
