export const CredentialHeader = () => import('../../components/credentials/CredentialHeader.vue' /* webpackChunkName: "components/credential-header" */).then(c => wrapFunctional(c.default || c))
export const EuropeanBankIdentity = () => import('../../components/credentials/EuropeanBankIdentity.vue' /* webpackChunkName: "components/european-bank-identity" */).then(c => wrapFunctional(c.default || c))
export const LegalPerson = () => import('../../components/credentials/LegalPerson.vue' /* webpackChunkName: "components/legal-person" */).then(c => wrapFunctional(c.default || c))
export const LegalPersonCopy = () => import('../../components/credentials/LegalPerson_copy.vue' /* webpackChunkName: "components/legal-person-copy" */).then(c => wrapFunctional(c.default || c))
export const OpenBadgeCredential = () => import('../../components/credentials/OpenBadgeCredential.vue' /* webpackChunkName: "components/open-badge-credential" */).then(c => wrapFunctional(c.default || c))
export const ParticipantCredential = () => import('../../components/credentials/ParticipantCredential.vue' /* webpackChunkName: "components/participant-credential" */).then(c => wrapFunctional(c.default || c))
export const ProofOfResidence = () => import('../../components/credentials/ProofOfResidence.vue' /* webpackChunkName: "components/proof-of-residence" */).then(c => wrapFunctional(c.default || c))
export const StudentID = () => import('../../components/credentials/StudentID.vue' /* webpackChunkName: "components/student-i-d" */).then(c => wrapFunctional(c.default || c))
export const VerifiableDiploma = () => import('../../components/credentials/VerifiableDiploma.vue' /* webpackChunkName: "components/verifiable-diploma" */).then(c => wrapFunctional(c.default || c))
export const VerifiableID = () => import('../../components/credentials/VerifiableID.vue' /* webpackChunkName: "components/verifiable-i-d" */).then(c => wrapFunctional(c.default || c))
export const VerifiableVaccinationCertificate = () => import('../../components/credentials/VerifiableVaccinationCertificate.vue' /* webpackChunkName: "components/verifiable-vaccination-certificate" */).then(c => wrapFunctional(c.default || c))
export const EuropassAchievedComponent = () => import('../../components/credentials/Europass/AchievedComponent.vue' /* webpackChunkName: "components/europass-achieved-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassAchievedSpecifiedByComponent = () => import('../../components/credentials/Europass/AchievedSpecifiedByComponent.vue' /* webpackChunkName: "components/europass-achieved-specified-by-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassEntitlesToComponent = () => import('../../components/credentials/Europass/EntitlesToComponent.vue' /* webpackChunkName: "components/europass-entitles-to-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassEntitlesToSpecifiedByComponent = () => import('../../components/credentials/Europass/EntitlesToSpecifiedByComponent.vue' /* webpackChunkName: "components/europass-entitles-to-specified-by-component" */).then(c => wrapFunctional(c.default || c))
export const Europass = () => import('../../components/credentials/Europass/Europass.vue' /* webpackChunkName: "components/europass" */).then(c => wrapFunctional(c.default || c))
export const EuropassGradingSchemeComponent = () => import('../../components/credentials/Europass/GradingSchemeComponent.vue' /* webpackChunkName: "components/europass-grading-scheme-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassHasPartComponent = () => import('../../components/credentials/Europass/HasPartComponent.vue' /* webpackChunkName: "components/europass-has-part-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassHasPartSpecifiedByComponent = () => import('../../components/credentials/Europass/HasPartSpecifiedByComponent.vue' /* webpackChunkName: "components/europass-has-part-specified-by-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassIdentifierComponent = () => import('../../components/credentials/Europass/IdentifierComponent.vue' /* webpackChunkName: "components/europass-identifier-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassItemValueComponent = () => import('../../components/credentials/Europass/ItemValueComponent.vue' /* webpackChunkName: "components/europass-item-value-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassLoopLiComponent = () => import('../../components/credentials/Europass/LoopLiComponent.vue' /* webpackChunkName: "components/europass-loop-li-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassWasAwardedByComponent = () => import('../../components/credentials/Europass/WasAwardedByComponent.vue' /* webpackChunkName: "components/europass-was-awarded-by-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassWasDerivedFromComponent = () => import('../../components/credentials/Europass/WasDerivedFromComponent.vue' /* webpackChunkName: "components/europass-was-derived-from-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassWasDerivedSpecifiedByComponent = () => import('../../components/credentials/Europass/WasDerivedSpecifiedByComponent.vue' /* webpackChunkName: "components/europass-was-derived-specified-by-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassWasInfluencedByComponent = () => import('../../components/credentials/Europass/WasInfluencedByComponent.vue' /* webpackChunkName: "components/europass-was-influenced-by-component" */).then(c => wrapFunctional(c.default || c))
export const EuropassWasInfluencedSpecifiedByComponent = () => import('../../components/credentials/Europass/WasInfluencedSpecifiedByComponent.vue' /* webpackChunkName: "components/europass-was-influenced-specified-by-component" */).then(c => wrapFunctional(c.default || c))
export const BlockingButtonComponent = () => import('../../components/BlockingButtonComponent.vue' /* webpackChunkName: "components/blocking-button-component" */).then(c => wrapFunctional(c.default || c))
export const CredentialView = () => import('../../components/CredentialView.vue' /* webpackChunkName: "components/credential-view" */).then(c => wrapFunctional(c.default || c))
export const ErrorMessage = () => import('../../components/ErrorMessage.vue' /* webpackChunkName: "components/error-message" */).then(c => wrapFunctional(c.default || c))
export const ImageComponent = () => import('../../components/ImageComponent.vue' /* webpackChunkName: "components/image-component" */).then(c => wrapFunctional(c.default || c))
export const Notice = () => import('../../components/Notice.vue' /* webpackChunkName: "components/notice" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const TokenMediaComponent = () => import('../../components/TokenMediaComponent.vue' /* webpackChunkName: "components/token-media-component" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
