import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _49473dac = () => interopDefault(import('../pages/AddCredential.vue' /* webpackChunkName: "pages/AddCredential" */))
const _19a2e8ae = () => interopDefault(import('../pages/Connections.vue' /* webpackChunkName: "pages/Connections" */))
const _671eb1b3 = () => interopDefault(import('../pages/Credential.vue' /* webpackChunkName: "pages/Credential" */))
const _080eec22 = () => interopDefault(import('../pages/credential-offer.vue' /* webpackChunkName: "pages/credential-offer" */))
const _491f6be8 = () => interopDefault(import('../pages/CredentialRequest.vue' /* webpackChunkName: "pages/CredentialRequest" */))
const _ff39b460 = () => interopDefault(import('../pages/Credentials.vue' /* webpackChunkName: "pages/Credentials" */))
const _1c443d3b = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _4233fc20 = () => interopDefault(import('../pages/InitiateIssuance.vue' /* webpackChunkName: "pages/InitiateIssuance" */))
const _b2e45c7e = () => interopDefault(import('../pages/IssuanceError.vue' /* webpackChunkName: "pages/IssuanceError" */))
const _ebaf5e86 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _78ecaab6 = () => interopDefault(import('../pages/nfts/index.vue' /* webpackChunkName: "pages/nfts/index" */))
const _2b8a1e6e = () => interopDefault(import('../pages/ReceiveCredential.vue' /* webpackChunkName: "pages/ReceiveCredential" */))
const _504507c7 = () => interopDefault(import('../pages/ScanQRCode.vue' /* webpackChunkName: "pages/ScanQRCode" */))
const _1d246bc2 = () => interopDefault(import('../pages/Settings/index.vue' /* webpackChunkName: "pages/Settings/index" */))
const _073bb3f4 = () => interopDefault(import('../pages/Signup.vue' /* webpackChunkName: "pages/Signup" */))
const _2dc44178 = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _789ed026 = () => interopDefault(import('../pages/Settings/data/index.vue' /* webpackChunkName: "pages/Settings/data/index" */))
const _7a1f22d4 = () => interopDefault(import('../pages/Settings/Ecosystems/index.vue' /* webpackChunkName: "pages/Settings/Ecosystems/index" */))
const _33915ef6 = () => interopDefault(import('../pages/verify/ScanNFT.vue' /* webpackChunkName: "pages/verify/ScanNFT" */))
const _225553f7 = () => interopDefault(import('../pages/Settings/Ecosystems/cheqd.vue' /* webpackChunkName: "pages/Settings/Ecosystems/cheqd" */))
const _45afe26d = () => interopDefault(import('../pages/Settings/Ecosystems/dns.vue' /* webpackChunkName: "pages/Settings/Ecosystems/dns" */))
const _0dc270b4 = () => interopDefault(import('../pages/Settings/Ecosystems/ebsi-essif.vue' /* webpackChunkName: "pages/Settings/Ecosystems/ebsi-essif" */))
const _3a571b6e = () => interopDefault(import('../pages/Settings/Ecosystems/gaia-x.vue' /* webpackChunkName: "pages/Settings/Ecosystems/gaia-x" */))
const _9e094d02 = () => interopDefault(import('../pages/Settings/Ecosystems/iota.vue' /* webpackChunkName: "pages/Settings/Ecosystems/iota" */))
const _ae171eba = () => interopDefault(import('../pages/Settings/Ecosystems/key.vue' /* webpackChunkName: "pages/Settings/Ecosystems/key" */))
const _0f62042c = () => interopDefault(import('../pages/Settings/data/key/Import.vue' /* webpackChunkName: "pages/Settings/data/key/Import" */))
const _490e8dc6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7ed62091 = () => interopDefault(import('../pages/nfts/_chain/index.vue' /* webpackChunkName: "pages/nfts/_chain/index" */))
const _9835d98e = () => interopDefault(import('../pages/nfts/_chain/_id.vue' /* webpackChunkName: "pages/nfts/_chain/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/AddCredential",
    component: _49473dac,
    name: "AddCredential"
  }, {
    path: "/Connections",
    component: _19a2e8ae,
    name: "Connections"
  }, {
    path: "/Credential",
    component: _671eb1b3,
    name: "Credential"
  }, {
    path: "/credential-offer",
    component: _080eec22,
    name: "credential-offer"
  }, {
    path: "/CredentialRequest",
    component: _491f6be8,
    name: "CredentialRequest"
  }, {
    path: "/Credentials",
    component: _ff39b460,
    name: "Credentials"
  }, {
    path: "/Home",
    component: _1c443d3b,
    name: "Home"
  }, {
    path: "/InitiateIssuance",
    component: _4233fc20,
    name: "InitiateIssuance"
  }, {
    path: "/IssuanceError",
    component: _b2e45c7e,
    name: "IssuanceError"
  }, {
    path: "/Login",
    component: _ebaf5e86,
    name: "Login"
  }, {
    path: "/nfts",
    component: _78ecaab6,
    name: "nfts"
  }, {
    path: "/ReceiveCredential",
    component: _2b8a1e6e,
    name: "ReceiveCredential"
  }, {
    path: "/ScanQRCode",
    component: _504507c7,
    name: "ScanQRCode"
  }, {
    path: "/Settings",
    component: _1d246bc2,
    name: "Settings"
  }, {
    path: "/Signup",
    component: _073bb3f4,
    name: "Signup"
  }, {
    path: "/verify",
    component: _2dc44178,
    name: "verify"
  }, {
    path: "/Settings/data",
    component: _789ed026,
    name: "Settings-data"
  }, {
    path: "/Settings/Ecosystems",
    component: _7a1f22d4,
    name: "Settings-Ecosystems"
  }, {
    path: "/verify/ScanNFT",
    component: _33915ef6,
    name: "verify-ScanNFT"
  }, {
    path: "/Settings/Ecosystems/cheqd",
    component: _225553f7,
    name: "Settings-Ecosystems-cheqd"
  }, {
    path: "/Settings/Ecosystems/dns",
    component: _45afe26d,
    name: "Settings-Ecosystems-dns"
  }, {
    path: "/Settings/Ecosystems/ebsi-essif",
    component: _0dc270b4,
    name: "Settings-Ecosystems-ebsi-essif"
  }, {
    path: "/Settings/Ecosystems/gaia-x",
    component: _3a571b6e,
    name: "Settings-Ecosystems-gaia-x"
  }, {
    path: "/Settings/Ecosystems/iota",
    component: _9e094d02,
    name: "Settings-Ecosystems-iota"
  }, {
    path: "/Settings/Ecosystems/key",
    component: _ae171eba,
    name: "Settings-Ecosystems-key"
  }, {
    path: "/Settings/data/key/Import",
    component: _0f62042c,
    name: "Settings-data-key-Import"
  }, {
    path: "/",
    component: _490e8dc6,
    name: "index"
  }, {
    path: "/nfts/:chain",
    component: _7ed62091,
    name: "nfts-chain"
  }, {
    path: "/nfts/:chain/:id",
    component: _9835d98e,
    name: "nfts-chain-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
